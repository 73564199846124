/* eslint-disable no-console */
import * as React from "react";
import reactGuard from "react-guard";

type ComponentInfo = {
  props?: {
    [key: string]: any;
  };
  state?: {
    [key: string]: any;
  };
  displayName?: string;
};

type RenderInfo = {
  name?: string;
  props?: {
    [key: string]: any;
  };
  state?: {
    [key: string]: any;
  };
};
export type RenderError = { message: string; stack: string; info: RenderInfo };
const errorLog: Array<RenderError> = [];

function flattenChildren(props?: { [key: string]: any }) {
  if (!props) return props;
  if (props.children) {
    if (!Array.isArray(props.children) && props.children.type) {
      return { ...props, children: props.children.type };
    } else {
      return { ...props, children: undefined };
    }
  }

  return props;
}

/**
 * Hijack the React render method, allowing us to catch any errors that occurs during server rendering
 */
function hijack(
  instance: {
    [key: string]: any;
  } = React
) {
  // Catch and process component render exceptions.
  reactGuard(instance, (err: Error, componentInfo: ComponentInfo) => {
    // Convert to JSON and back again - This strips out methods, and other content that can't be serialized
    const info: RenderInfo = JSON.parse(
      JSON.stringify({
        name: componentInfo.displayName,
        props: flattenChildren(componentInfo.props),
        state: componentInfo.state,
      })
    );

    if (console && console.error) {
      // Print stacktrace to the console
      console.error(
        `${err.stack}\n\nComponent: ${JSON.stringify(info, null, 2)}`
      );
    }
    errorLog.push({
      message: err.message,
      stack: err.stack ?? "",
      info,
    });

    // Replace failed component output with return value. Return null to render nothing.
    return null;
  });
}

/**
 * Release the hijacked React render
 */
function release(
  instance: {
    [key: string]: any;
  } = React
) {
  reactGuard.restore(instance);
}

/**
 * Flush the log with the current result. Do this after each render
 **/
function flush(
  restore = true,
  instance: {
    [key: string]: any;
  } = React
): Array<RenderError> | null | undefined {
  const errors = errorLog.length ? errorLog.concat() : null;
  if (restore) release(instance);

  if (errors) {
    errorLog.length = 0;

    return errors;
  }

  return null;
}

export default {
  hijack,
  release,
  flush,
};
