const modulesMap = new Map();
const modulesList = require("../../modules/modulesList");

/**
 * Dynamically load all the modules that should be added to the map
 * When rendering serverside we use the static `staticModulesMap.js`, while the client uses the `dynamicModulesMap.js` file.
 *
 * Current we look for modules in:
 * * `src/modules/`
 */
function createModules() {
  modulesList.default.forEach((module) => {
    if (process.env.SERVER) {
      const getStaticModule = require("../../modules/staticModulesMap").default;
      modulesMap.set(module, getStaticModule(module));
    } else {
      const getDynamicModule = require("../../modules/dynamicModulesMap")
        .default;
      modulesMap.set(module, getDynamicModule(module));
    }
  });
}

export function getModule(name: any): any {
  if (!name) return null;

  if (process.env.NODE_ENV === "test") {
    const getStaticModule = require("../../modules/staticModulesMap").default;

    return getStaticModule(name);
  }

  if (!modulesMap.size) createModules();

  return modulesMap.get(name);
}

if (module.hot) {
  // If this module is reloaded, it should dispose the modules maps so it can be refreshed on next render
  module.hot.dispose(() => {
    modulesMap.clear();
  });
}
