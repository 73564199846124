import { DimensionNames } from "./media";

export type Theme = {
  style: "light" | "dark";
  bottom: number;
  color: typeof color;
};
type SpacingsEntry = { [K in DimensionNames]?: number };
type Spacings = { [key: string]: SpacingsEntry };

/**
 * The initial Theme passed to the styled-components ThemeProvider
 */
export const color = {
  newOrange: "#FF6C0C",
  orange: "#FF6C0C",
  white: "#FFF",
  bg: "#F4F4F4",
  light: "#ECECEC",
  lightGrey: "#A1A1B1",
  grey: "#727484", // changed from #747589 after webtilgængelighed test
  greyLink: "#747589",
  mediumGrey: " #D8D8D8",
  darkGrey: "#434363",
  opaqueDarkGrey: "#14143c1a",
  blue: "#14143C",
  altBlue: "#14143d",
  darkBlue: "#15153d",
  black: "#000",
  red: "#d10a11",
  redAlert: "#d10a11",
  disabled: "#d2d2da",
  paleLilac: "#e4e4ea",
  paleGrey: "#fcfcfd",
  paleGrey2: "#f7f7f9",
  slateGrey: "#6f7180", // changed from #696a79 after webtilgængelighed test
  softAlert: "#f8dfce",
  borderGrey: "#e6e6e6",
  formHover: "#d0d0d8",
  mediumBlue: "#29294d",
};

export const fontFamily =
  "Academy Sans, system-ui, Helvetica Neue, Arial, sans-serif";

export const effects = {
  cardShadow: "0 2px 9px 0 rgba(20, 20, 60, 0.1)",
  cardShadowNoTop: "-2px 14px 12px 0 rgba(20, 20, 61, 0.11)",
};

export const spacings: Spacings = {
  default: {
    xs: 25,
  },
  modules: {
    xs: 50,
    sm: 60,
  },
};

const mainBar = {
  sm: 100,
};
const utilsBar = {
  sm: 40,
};
const bannerBar = {
  xs: 63,
  sm: 100,
};
export const heights = {
  header: {
    xs: 60,
    sm: mainBar.sm + utilsBar.sm + bannerBar.sm,
  },
  mainBar,
  utilsBar,
};

export const initialTheme: Theme = {
  color,
  bottom: 0,
  style: "light",
};
