/* eslint-disable no-console */

export type LogResult = {
  errors: Array<string> | null | undefined;
  warnings: Array<string> | null | undefined;
};

const orgError = console.error;
const orgWarn = console.warn;
const errorLog: Array<string> = [];
const warnLog: Array<string> = [];

const customError = (...args: Error[]) => {
  args.forEach((arg) => {
    if (arg) errorLog.push(arg.toString());
  });
  orgError.apply(console, args);
};

const customWarn = (...args: Error[]) => {
  args.forEach((arg) => {
    if (arg) warnLog.push(arg.toString());
  });
  orgWarn.apply(console, args);
};

/**
 * Hijack the console methods, with a custom implementation
 */
function hijack() {
  console.error = customError;
  console.warn = customWarn;
}

/**
 * Release the hijacked console methods
 */
function release() {
  console.error = orgError;
  console.warn = orgWarn;
}

/**
 * Flush the log with the current result. Do this after each render
 * */
function flush(restore = true): LogResult | null | undefined {
  const errors = errorLog.length ? errorLog.concat() : null;
  const warnings = warnLog.length ? warnLog.concat() : null;
  if (restore) release();

  if (errors || warnings) {
    errorLog.length = 0;
    warnLog.length = 0;

    return {
      errors,
      warnings,
    };
  }

  return null;
}

/**
 * Takes the result of a log.flush() and outputs a <script> tag that will print the messages to a console.
 * */
export function outputLogToScript(
  logResult: LogResult | null | undefined
): string {
  if (logResult) {
    const errors = logResult.errors
      ? logResult.errors
          .map((error) => `console.error(${JSON.stringify(error)})`)
          .join(";")
      : "";
    const warnings = logResult.warnings
      ? logResult.warnings
          .map((warn) => `console.warn(${JSON.stringify(warn)})`)
          .join(";")
      : "";

    return `<script>${errors};${warnings}</script>`;
  }

  return "";
}

export default {
  hijack,
  release,
  flush,
};
