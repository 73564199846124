import * as React from "react";
import { renderToString } from "react-dom/server";
import { ServerStyleSheet } from "styled-components";

import { flatModulesList } from "./App/app-modules/modules-utils";
import { ROOT_ID } from "./App/constants";
import Meta from "./App/Meta";
import { getModulesMap } from "./server/assets";
import { generateScripts } from "./server/html-utils";
import { fonts, getPreloadFonts } from "./styles/fonts";
import { HtmlConfigViewModel } from "./view-models/HtmlConfigViewModel";
import { HtmlViewModel } from "./view-models/HtmlViewModel";

type Props = {
  lang: string;
  config: HtmlConfigViewModel;
  children: React.ReactNode;
  model: HtmlViewModel;
};

function Html({ lang, children, model, config }: Props) {
  const sheet = new ServerStyleSheet();
  const content = renderToString(sheet.collectStyles(children));
  const styleElements = sheet.getStyleElement();

  const flatModules = flatModulesList(model.modules);
  const modulesMap = getModulesMap();
  const scripts = generateScripts();

  const preloadModules = [
    ...scripts,
    ...getPreloadScripts(
      // @ts-ignore
      flatModules.map((name) => modulesMap[name]).filter(Boolean)
    ),
  ];

  const preloadFonts = getPreloadFonts(getFontWeights(styleElements));

  return (
    <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="telephone=no" name="format-detection" />

        <Meta meta={model.meta} />
        {preloadFonts.map((url) => (
          <link
            key={url}
            as="font"
            crossOrigin="anonymous"
            href={url}
            rel="preload"
            type="font/woff2"
          />
        ))}
        {preloadModules.map((name) => (
          <link key={name} as="script" href={name} rel="preload" />
        ))}
        <link
          href="/static/images/apple-touch-icon.png"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/static/images/favicon-32x32.png"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/static/images/favicon-16x16.png"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          color="#5bbad5"
          href="/static/images/safari-pinned-tab.svg"
          rel="mask-icon"
        />
        <link href="/static/images/favicon.ico" rel="shortcut icon" />
        <link href="/static/manifest.json" rel="manifest" />
        <link
          href="https://customer.cludo.com/templates/2073/13793/dist/styles/cludo-search-results.css"
          rel="stylesheet"
          type="text/css"
        />
        <style dangerouslySetInnerHTML={{ __html: fonts.join("\n") }} />
        {styleElements}
      </head>
      <body>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          id={ROOT_ID}
          tabIndex={-1}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.__INITIAL=${JSON.stringify(
              model
            )};window.__CONFIG=${JSON.stringify(config)}`,
          }}
        />
        {scripts.map((src) => (
          <script key={src} defer src={src} />
        ))}

        {/*https://support.cookieinformation.com/en/articles/4418485-implementing-cookie-control-sdk#introduction*/}
        <script
          data-culture={lang}
          id="CookiePolicy"
          src="https://policy.app.cookieinformation.com/uc.js"
          type="text/javascript"
        />

        {/* load tracking with different endpoints */}
        {config.trackingEnabled &&
        config.endpoints &&
        config.endpoints.trackingURI ? (
          <script
            data-category-consent={"cookie_cat_statistic"}
            data-consent-src={config.endpoints.trackingURI}
            src={""}
          />
        ) : null}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.cludo_engineId = 13793; window.cludo_language = "da";
          window.cludo_searchUrl = "${model.meta.searchResultsURL}";`,
          }}
        ></script>
        <script
          src="https://customer.cludo.com/scripts/bundles/search-script.min.js"
          type="text/javascript"
        ></script>
        <script
          src="https://customer.cludo.com/templates/2073/13793/dist/js/cludo-search-results.js"
          type="text/javascript"
        ></script>
      </body>
    </html>
  );
}

function getPreloadScripts(modules: string[]): Array<string> {
  return [
    ...new Set(
      modules.reduce((acc, chunks) => {
        return [...acc, ...chunks];
      }, [])
    ),
  ];
}

/**
 * Find the font weights used on the page so we can preload them.
 */
function getFontWeights(styleElements): Array<string> {
  const findWeights = /font-weight:\d\d\d/g;

  return [
    // Filter unique weights
    ...new Set(
      styleElements
        .map((style) => {
          if (style.props && style.props.dangerouslySetInnerHTML)
            return style.props.dangerouslySetInnerHTML.__html || "";

          return "";
        })
        .join("")
        .match(findWeights)
    ),
  ]
    .map((val: string) => val.substr(-3, 3)) // Just extract the 3 digits weight number
    .sort();
}

export default Html;
