import "url-polyfill";

import { ApolloClient, ApolloLink, ApolloProvider } from "@apollo/client";
import { InMemoryCache, InMemoryCacheConfig } from "@apollo/client/cache";
import { resetLocalId } from "@charlietango/use-id";
import { resetIdCounter } from "downshift";
import { Provider } from "jotai";
import * as React from "react";
import ReactDOM from "react-dom/server";
import { I18nextProvider } from "react-i18next";

import { configAtom, metaAtom } from "@/store";

import App from "./App/App";
import { getModule } from "./App/app-modules/modules-map";
import { staticModules } from "./App/app-modules/modules-utils";
import Router from "./App/Router";
import StaticSpinner from "./components/Spinner/StaticSpinner";
import UserSession from "./graphql/UserSession";
import Html from "./html";
import { initI18n } from "./i18n";
import { generateStaticErrorView } from "./server/server-error-view";
import { RenderError } from "./server/server-errors";
import serverErrors from "./server/server-errors";
import serverLog, { outputLogToScript } from "./server/server-log";
import { generateModuleId } from "./utils/id";
import invariant from "./utils/invariant";
import { ComponentExtraViewModel } from "./view-models/ComponentExtraViewModel";
import { HtmlConfigViewModel } from "./view-models/HtmlConfigViewModel";
import { HtmlViewModel } from "./view-models/HtmlViewModel";

// Noop polyfills for methods that could be exposed to the server, but should just be ignored
// @ts-ignore
if (!global.setTimeout) global.setTimeout = () => {};
if (!global.clearTimeout) global.clearTimeout = () => {};

export type ComponentModule = {
  default: React.Component<any, any> | React.FunctionComponent<any>;
};

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Vurderingsejendom: {
      keyFields: ["vurderingsejendom_id"],
    },
    Vurderingssag: {
      keyFields: ["vurderingsejendom_id", "vurderingsaar"],
    },
  },
};

type Options = {
  id?: string;
  htmlElement?: "div" | "span" | "p";
  htmlAttributes?: {
    [key: string]: any;
  };
  onlyServer?: boolean;
  onlyClient?: boolean;
  debug?: boolean;
};

/**
 * Render the entire HTML page, so it can be rehydrated on the client
 **/
export async function renderHtml(
  data: HtmlViewModel,
  config: HtmlConfigViewModel = {}
) {
  try {
    if (process.env.PROD) {
      // Hijack the console and React.render, so we can capture errors and warnings to assist in debugging on the .NET server
      serverLog.hijack();
      serverErrors.hijack();
    }
    const meta = data.meta || {};
    const lang = meta.locale ? meta.locale.substr(0, 2) : "da";
    const apolloClient = new ApolloClient({
      ssrMode: true,
      cache: new InMemoryCache(cacheConfig),
      link: ApolloLink.from([]),
    });

    const html =
      "<!DOCTYPE html>" +
      ReactDOM.renderToStaticMarkup(
        <Html config={config} lang={lang} model={data}>
          <Provider
            initialValues={[
              [metaAtom, meta],
              [configAtom, config],
            ]}
          >
            <I18nextProvider i18n={initI18n(lang, config.i18n)}>
              <Router location={meta.absoluteUrl}>
                <ApolloProvider client={apolloClient}>
                  <UserSession
                    auth={config.auth}
                    initialModules={data.modules}
                    meta={meta}
                  >
                    <App
                      config={config}
                      initialModules={staticModules(data.modules)}
                    />
                  </UserSession>
                </ApolloProvider>
              </Router>
            </I18nextProvider>
          </Provider>
        </Html>
      );

    const log = serverLog.flush();
    const errors = serverErrors.flush();
    resetIdCounter();
    resetLocalId();

    return JSON.stringify({
      html: html + (config.debug ? outputLogToScript(log) : ""),
      log,
      errors,
    });
  } catch (err) {
    const { debug } = config;

    /* In debug mode render the Error using the ErrorBoundary, so the stack is shown in the HTML */
    const errorView = generateStaticErrorView(data, err);

    const log = serverLog.flush();
    const errorLog = serverErrors.flush() || [];
    resetIdCounter();
    resetLocalId();

    const errors: Array<RenderError> = [
      {
        message: err.message,
        stack: err.stack,
        info: { name: "Page", props: data },
      },
      ...errorLog,
    ];

    return JSON.stringify({
      html: `${errorView}${debug ? outputLogToScript(log) : ""}`,
      log,
      errors,
    });
  }
}

/**
 * Render a single React component.
 * Using this method instead of the standard ReactJS.NET method, gives us full control over the output.
 * Client side the components are loaded and rendered async, minimizing JS required to render a page.
 *
 * @param name - Exact name of the component to render
 * @param props - The props to be passed to the component
 * @param {Object} [options] - Options to control how the component is rendered
 * @param {string} options.id - Unique id to for the container element. A random id will be generated by default.
 * @param {string} options.htmlElement - Wrapping element
 * @param {Object} options.htmlAttributes - Attributes to add the wrapping element. Should be in react format, so class=className etc.
 * @param {boolean} options.debug - Add friendly error HTML output when components fails to render, and output console.error and console.warn messages in the browser.
 * @param {ComponentExtraViewModel} extra - Extra fields for the component
 */
export async function renderComponent(
  name: string,
  props: {
    [key: string]: any;
  },
  options: Options = {},
  extra: ComponentExtraViewModel
) {
  try {
    const component: ComponentModule | null | undefined = getModule(name);
    invariant(component, "%s is not a valid React Component.", name);

    // Hijack the console, so we can capture errors and warnings to assist in debugging on the .NET server
    if (process.env.PROD) {
      serverLog.hijack();
    }

    const id = options.id || generateModuleId(name);

    const script = generateClientScript({
      name,
      props,
      id,
      ...extra,
      debug: !!options.debug,
    });

    const innerHtml = StaticSpinner;

    const log = serverLog.flush();

    return JSON.stringify({
      errors: null,
      log,
      html: `${generateHtml(
        innerHtml || "",
        id,
        name,
        options.htmlElement,
        options.htmlAttributes
      )}${options.debug ? outputLogToScript(log) : ""}${script || ""}`,
    });
  } catch (err) {
    const id = options.id || generateModuleId(name);
    const { debug } = options;

    /* In debug mode render the Error using the ErrorBoundary, so the stack is shown in the HTML */
    const errorView = debug ? generateStaticErrorView(props, err) : "";

    const log = serverLog.flush();

    return JSON.stringify({
      errors: err.stack,
      log,
      html: `${generateHtml(errorView, id, name)}${generateClientScript({
        name,
        props,
        id,
        error: err.stack,
        debug: !!options.debug,
      })}${debug ? outputLogToScript(log) : ""}`,
    });
  }
}

/**
 * Create the HTML wrapper for a component
 * @param innerHtml
 * @param id
 * @param name {string}
 * @param htmlElement {string}
 * @param htmlAttributes {Object}
 * @returns {*}
 */
function generateHtml(
  innerHtml: string,
  id: string,
  name: string,
  htmlElement = "div",
  htmlAttributes = {}
) {
  return ReactDOM.renderToStaticMarkup(
    React.createElement(htmlElement, {
      ...htmlAttributes,
      "data-module": name,
      id,
      dangerouslySetInnerHTML: { __html: innerHtml },
    })
  );
}

/**
 * Create the JS code needed to initialize a server rendered component.
 * @param values.name
 * @param values.props
 * @param values.id
 * @param values.error
 * @returns {string}
 */
function generateClientScript(values) {
  if (!values) return "";

  return `<script data-hydrate type="application/json">${JSON.stringify(
    values
  )}</script>`;
}
