type Assets = {
  [key: string]: Array<string>;
};

type ModuleMap = {
  [key: string]: Array<string>;
};

/**
 * The the exported assets from Webpack - Use the generated assets file in production mode.
 */
export function getAssets(): Assets {
  if (process.env.PROD) {
    return Object(process.env.EXPORTED_ASSETS); // eslint-disable-line
  } else {
    return {
      client: ["/static/scripts/client.js"],
    };
  }
}

/**
 * ModulesMap maps module names to their external resource file. This allows us flag required files for preloading.
 **/
export function getModulesMap(): ModuleMap {
  if (process.env.PROD) {
    return Object(process.env.EXPORTED_MODULES); // eslint-disable-line
  } else {
    return {};
  }
}
