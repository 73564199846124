import { css } from "styled-components";

import black_woff from "./fonts/AcademySans-Black.woff";
import black from "./fonts/AcademySans-Black.woff2";
import bold_woff from "./fonts/AcademySans-Bold.woff";
import bold from "./fonts/AcademySans-Bold.woff2";
import demiBold_woff from "./fonts/AcademySans-Demibold.woff";
import demiBold from "./fonts/AcademySans-Demibold.woff2";
import italic_woff from "./fonts/AcademySans-Italic.woff";
import italic from "./fonts/AcademySans-Italic.woff2";
import medium_woff from "./fonts/AcademySans-Medium.woff";
import medium from "./fonts/AcademySans-Medium.woff2";
import regular_woff from "./fonts/AcademySans-Regular.woff";
import regular from "./fonts/AcademySans-Regular.woff2";

const weights = {
  "400": regular,
  "500": medium,
  "600": demiBold,
  "700": bold,
  "900": black,
};

export type FontSizes = "normal" | "small" | "tiny" | "large";

const fontFace = (
  woff2_src: string,
  woff_src: string,
  fontWeight = 400,
  fontStyle = "normal"
) => `@font-face{
  font-family: "Academy Sans";
  src: url(${woff2_src}) format("woff2"),
    url(${woff_src}) format("woff");
  font-weight: ${fontWeight};
  font-style: ${fontStyle};
  font-display: fallback;
}`;

export const fonts = css`
  ${fontFace(regular, regular_woff)}
  ${fontFace(italic, italic_woff, 400, "italic")}
  ${fontFace(medium, medium_woff, 500)}
  ${fontFace(demiBold, demiBold_woff, 600)}
  ${fontFace(bold, bold_woff, 700)}
  ${fontFace(black, black_woff, 900)}
`;

export function getPreloadFonts(w: Array<string>): Array<string> {
  return w.map((weight) => weights[weight]);
}
