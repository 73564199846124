import * as React from "react";
import ReactDOM from "react-dom/server";
import { ServerStyleSheet } from "styled-components";

import FriendlyError from "../components/Errors/FriendlyError/FriendlyError";
import Main from "../components/Main/Main";
import GobalStyles from "../styles/global-styles";

export function generateStaticErrorView(
  props: {
    [key: string]: any;
  },
  error: Error
) {
  // Inject the global styles into the static view

  const errorEl = (
    <Main>
      <GobalStyles />
      <FriendlyError
        debug
        description="Check the props and stacktrace for details"
        details={[
          { name: "Props", value: props },
          {
            name: "Stacktrace",
            value: error ? error.stack : "",
          },
        ]}
        name="Page"
        outline={false}
        title="An error occurred when rendering the page"
      />
    </Main>
  );
  const sheet = new ServerStyleSheet();
  const content = ReactDOM.renderToStaticMarkup(sheet.collectStyles(errorEl));

  return `${sheet.getStyleTags()}${content}`;
}
