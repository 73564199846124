import { ValuationYearViewModel } from "@/view-models/ValuationYearViewModel";

import { Vurderingsejendom } from "../graphql/__generated__/privateTypes";

export type PropertyType = Vurderingsejendom;

export const getLatestValuationYear = (property: PropertyType) => {
  return property.vurderingssager
    ? (property.vurderingssager ?? []).reduce((prev: number, next) => {
        const nextYear = next?.vurderingsaar ?? -1;

        return prev === -1 ? nextYear : prev > nextYear ? prev : nextYear;
      }, -1)
    : -1;
};

/**
 * Take an Array<MyPropertyLinkViewModel> and a year, return the link matching the passed in year.
 * Fallback to first item of array if no match or else empty object
 *
 * @param {Array<ValuationYearViewModel>} valuationYearLinks
 * @param {number} year
 */
export const getValuationYearLink = (
  valuationYearLinks: Array<ValuationYearViewModel>,
  year: number
) => {
  let linksForValuationYear: ValuationYearViewModel | undefined =
    valuationYearLinks?.find((valuationYear) => valuationYear.year === year);

  //Fallback to the nearest lower number
  if (!linksForValuationYear) {
    for (let index = 0; index < valuationYearLinks.length; index++) {
      const currentValue = valuationYearLinks[index];

      if (currentValue.year < year) {
        linksForValuationYear = currentValue;
        break;
      }
    }
  }

  //If the first fallback failed get the latest
  if (!linksForValuationYear) {
    linksForValuationYear = valuationYearLinks?.reduce((max, value) =>
      max.year > value.year ? max : value
    );
  }

  return linksForValuationYear;
};

/**
 *  Takes URL string and returns new URL string with search parameter for
 *  "ejendomsid" (propertyId) added
 *
 * @param {string} [url=""]
 * @param {number} propertyId
 * @returns {string} url string with search param
 */
export const getUrlString = (url = "", propertyId: number) => {
  let urlObj: URL;
  if (!url.match(/https?:\/\/.*/)) {
    urlObj = new URL(window.location.href);
    urlObj.pathname = url;
  } else {
    urlObj = new URL(url);
  }
  urlObj.searchParams.set("ejendomsid", String(propertyId));

  return urlObj.toString();
};
