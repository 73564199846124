import { getAssets } from "./assets";

export function generateScripts(): Array<string> {
  const assets = getAssets();

  return Object.keys(assets)
    .reduce((acc, key) => {
      return [...acc, ...assets[key]];
    }, [])
    .filter(Boolean);
}
