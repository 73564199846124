import * as React from "react";

import { MetaViewModel } from "@/view-models/MetaViewModel";

type Props = {
  meta: MetaViewModel;
};

/**
 * Renders meta information that's specific for the current page
 **/
function Meta({ meta }: Props) {
  return (
    <React.Fragment>
      <title>{meta.title || meta.siteName}</title>
      {/* Title is set by AppShell, so it gets updated when content changes */}
      <meta content={meta.description} name="description" />
      <link href={meta.absoluteUrl} rel="canonical" />
      {/* Render Opengraph tags */}
      <meta content={meta.title} property="og:title" />
      <meta content={meta.siteName} property="og:site_name" />
      <meta content={meta.description} property="og:description" />
      <meta content={meta.locale} property="og:locale" />
      <meta content={meta.absoluteUrl} property="og:url" />
      {meta.noIndex ? <meta content="noindex, nofollow" name="robots" /> : null}
      {meta.behindAuth ? (
        <meta content="no-store" httpEquiv="cache-control" />
      ) : null}
      {meta.image && (
        <React.Fragment>
          <meta content={meta.image.src} property="og:image" />
          <meta content={`${meta.image.width}`} property="og:image:width" />
          <meta content={`${meta.image.height}`} property="og:image:height" />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

Meta.defaultProps = {
  meta: {
    behindAuth: false,
  },
};

export default Meta;
