import { gql } from "@apollo/client";
import { request } from "graphql-request";
import { atom } from "jotai";

import {
  OffentligVurdering,
  OffentligVurdering_OffentligVurdering_vurderingsejendom_adresse_Adresse as Adresse,
} from "@/graphql/__generated__/publicTypes";
import { PublicDataPoints } from "@/utils/hooks/usePublicAssessment";

import { configAtom } from "./index";

const PUBLIC_ASSESSMENT = gql`
  fragment OffentligVurdering on OffentligVurdering {
    BFEnumre {
      BFEnummer
    }
    ejendomsoplysninger {
      boligdelens_fordelte_vaerdi
      boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2001
      boligedel_tilbageregnet_reguleret_ejendomsvaerdi_2002
      ejendomsvaerdi
      tilbageregnet_ejendomsvaerdi_2001
      tilbageregnet_ejendomsvaerdi_2002
      vaerdi_oevrig
      juridisk_kategori {
        kategori_tekst
        underkategori_tekst
      }
      ejendom_delvurderinger {
        fletteflag {
          delvis_undtagelse
          hel_undtagelse
        }
        adresse
        boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2001
        boligenhedens_tilbageregnet_reguleret_ejendomsvaerdi_2002
        fordelt_vaerdi
      }
    }
    hero_billede(bredde: 1440) {
      url
      kortudsnit
      stoerrelse {
        bredde
        hoejde
      }
    }
    fletteflag {
      ev {
        ev_ansaettes
        ejendom_delvist_undtaget_delvurderinger_ejendomsniveau
        ejendom_paa_5000kvm_i_landzone
        ev_uden_beskatningsmaessigt_formaal
        hvis_to_boligenheder
        markering_for_godtgoerelse_ev
        regulering_ev_nedrivning__tilbygning_byggetilladelse
        reguleringsgrund_ev
        tilbageregningsgrund_ev
        hvis_ejerlejlighed
        offentlig_ejendom_kun_grundvaerdi
      }
      gv {
        gv_ansaettes
        ejendom_min_en_delvist_fritaget_delgrund_ejendomsniveau
        ejendom_min_en_hel_fritaget_delgrund
        ejendom_offentlig_ejet_daekningsafgiftspligtig_forskelsvaerdien
        fradrag_for_forbedringer
        gv_uden_beskatningsmaessigt_formaal
        manuel_fastsaettelse_FFF
        manuel_regulering_gv
        markering_for_godtgoerelse_gv
        offentlig_ejendom_grundskyld_betales
        offentlig_ejendom_udlejes_erhverv
        reguleret_fradrag_pba_regulering_gv
        reguleret_fradrag_pba_tilbageregning_gv
        tilbageregningsgrund_gv
        ejendom_offentlig_ejet_daekningsafgiftspligtig
      }
      K113
      K114
      K47
      K49
      K50
      K160
      delvis_jordrentepligt
      fritagelse_grundskyld_hel
      undtaget_for_vurdering
      jord_i_flere_kommuner
      beliggenhedsadresse_mangler
    }
    grund {
      ejerlav {
        matrikler {
          matrikelnummer
        }
        navn
      }
      grund_delvurderinger {
        fletteflag {
          K28s
          K34s
          K35s
          K43s
          delvis_undtagelse
          hel_delvurdering_er_undtaget
        }
      }
      fradrag_grundforbedringer_samlet_vaerdi
      fradrag_grundforbedringer_vardi_minus_fritagelse
      grundvaerdi
      grundvaerdi_efter_fritagelse
      jordrentepligtig_dels_fff_andel
      jordrentepligtvaerdi
      tilbageregnet_grundvaerdi
      vaerdi_samlet_fradrag_pba_tilbage_regulering
      daekningsafgiftspligtig_grundvaerdi
    }
    vurderingsejendom_adresse {
      adgangsadresse_id
      adresse_id
      adressebetegnelse
      doer
      etage
      husnr
      postnr
      postnr_navn
      supplerende_bynavn
      vejnavn
    }
    vurderingsejendom_id
    vurderingsaar
    sag_id
    vurderingstermindato
    metainformation {
      schema
    }
  }
`;

const GET_ADRESSE_DATA = gql`
  ${PUBLIC_ASSESSMENT}

  query GetOffentligeVurderingerPaaAdresse($id: UUID!) {
    offentlige_vurderinger_paa_adresse(adresse_id: $id) {
      ...OffentligVurdering
    }
  }
`;

const GET_BFE_DATA = gql`
  ${PUBLIC_ASSESSMENT}

  query GetOffentligeVurderingerPaaBfe($id: String!) {
    offentlige_vurderinger_paa_bfe(bfe_nummer: $id) {
      ...OffentligVurdering
    }
  }
`;

export const publicAddressAtom = atom<Adresse, Adresse>(
  {} as Adresse,
  (get, set, value) => {
    set(publicAddressAtom, value);
  }
);

export const publicAddressPropertyIdAtom = atom(
  (get) => get(publicAddressAtom)?.adgangsadresse_id
);

/** contains the public assessment for the current public address. */
export const publicAssessmentAtom = atom<
  Array<OffentligVurdering>,
  Array<OffentligVurdering>
>([] as Array<OffentligVurdering>, (get, set, value) => {
  set(publicAssessmentAtom, value);
});

/** contains a list of available assessment years for the current property */
export const assessmentYearsAtom = atom<number[]>((get) => {
  const assessmentYears = get(publicAssessmentAtom)
    ?.map((valuation) => valuation.vurderingsaar)
    .filter(Boolean) as number[];

  // filter duplicate years
  return [...new Set(assessmentYears)];
});

/** for handling of loading state when fetching a public assessment */
export const publicAssessmentLoadingAtom = atom<boolean, boolean>(
  false,
  (get, set, value) => {
    set(publicAssessmentLoadingAtom, value);
  }
);

/** for error handling when fetching a public assessment  */
export const publicAssessmentErrorAtom = atom<null | Error, Error>(
  null,
  (get, set, value) => {
    set(publicAssessmentErrorAtom, value);
  }
);

/** controls the current active assessment year which determines the value of the `activeAssessmentAtom`. */
export const activeAssessmentYearAtom = atom<undefined | number, number>(
  undefined,
  (get, set, value) => {
    set(activeAssessmentYearAtom, value);
  }
);

/** the current active assessment corresponding to the value of the `activeAssessmentYearAtom` */
export const activeAssessmentAtom = atom((get) => {
  const assessmentData = get(publicAssessmentAtom);
  const activeAssessmentYear = get(activeAssessmentYearAtom);

  return (
    assessmentData?.find(
      (valuation) => valuation?.vurderingsaar === activeAssessmentYear
    ) ?? null
  );
});

/**
 * Formatted data for the current active assessment. (`activeAssessmentAtom`)
 */

function compareVersion(
  schemaVersion: string,
  minimumRequiredVersion: string
): boolean {
  const versionParts = schemaVersion.substring(3).split(".").map(Number); // don't include "DM" and "VM" in the comparison
  const targetParts = minimumRequiredVersion.split(".").map(Number);

  for (let i = 0; i < Math.max(versionParts.length, targetParts.length); i++) {
    const versionPart = versionParts[i] || 0;
    const targetPart = targetParts[i] || 0;

    if (versionPart < targetPart) {
      return false;
    } else if (versionPart > targetPart) {
      return true;
    }
  }

  return true;
}

export const publicDataPointsAtom = atom<PublicDataPoints>((get) => {
  const activeAssessment = get(activeAssessmentAtom);

  const matrikelBetegnelse = activeAssessment?.grund?.ejerlav
    ?.map((lav) => {
      const matrikelNumre = lav?.matrikler
        ? lav?.matrikler.map((matrikel) => matrikel?.matrikelnummer)
        : [];

      if (lav?.navn) matrikelNumre.push(lav?.navn);

      return matrikelNumre;
    })
    .flat()
    .filter(Boolean)
    .join(", ");

  const ejerlavMatrikler = activeAssessment?.grund?.ejerlav
    ?.map((lav) =>
      lav?.matrikler
        ? lav?.matrikler.map((matrikel) => matrikel?.matrikelnummer)
        : undefined
    )
    .filter(Boolean);

  const ejerLavMartiklerString = ejerlavMatrikler?.join(", ");
  const schema = activeAssessment?.metainformation?.schema
    ? activeAssessment?.metainformation?.schema
    : "";

  const juridiskKategori =
    activeAssessment?.ejendomsoplysninger?.juridisk_kategori?.kategori_tekst;

  return {
    adgangsadresse_id: activeAssessment?.vurderingsejendom_adresse?.adresse_id,
    ejendomsvaerdi: activeAssessment?.ejendomsoplysninger?.ejendomsvaerdi,
    grundvaerdi: activeAssessment?.grund?.grundvaerdi,
    grundvaerdi_efter_fritagelse:
      activeAssessment?.grund?.grundvaerdi_efter_fritagelse,
    jordrentepligtvaerdi: activeAssessment?.grund?.jordrentepligtvaerdi,
    sag_id: activeAssessment?.sag_id,
    vurderingstermindato: activeAssessment?.vurderingstermindato,
    vurderingsaar: activeAssessment?.vurderingsaar,
    vurderingsejendom_id: activeAssessment?.vurderingsejendom_id,
    bfe_nummer:
      activeAssessment?.BFEnumre?.length === 1
        ? activeAssessment?.BFEnumre[0].BFEnummer
        : undefined,
    bfe_numre:
      Array.isArray(activeAssessment?.BFEnumre) &&
      activeAssessment.BFEnumre.length > 1
        ? activeAssessment?.BFEnumre
        : undefined,
    matrikel_betegnelse: matrikelBetegnelse,
    ejerlav_matrikler: ejerLavMartiklerString,
    juridisk_kategori: compareVersion(schema, "23.3.0")
      ? juridiskKategori
      : juridiskKategori
      ? juridiskKategori
      : "Ejerbolig til vurdering i lige år",
  };
});

/**
 * When the publid address id is set, data will be fetched for the public address data corrosponding to the given Id.
 *
 * And if a `vurderingsejendom_id` exists on the public address data then it will attempt to fetch the a public assessment for that id.
 * ___
 * The API responses will update the following atoms: `publicAddressAtom` & `publicAssessmentAtom`
 */
export const publicAddressIdAtom = atom<
  { id?: string; type: "address" | "bfe" } | null,
  { id?: string; type: "address" | "bfe" }
>(null, (get, set, value) => {
  if (
    value?.id === get(publicAddressIdAtom)?.id &&
    value?.type === get(publicAddressIdAtom)?.type
  )
    return;
  set(publicAddressIdAtom, value);
  const config = get(configAtom);
  const endpoint = config?.endpoints?.public;

  if (!endpoint || !value || !value.id) return;
  set(publicAssessmentAtom, null);
  set(publicAddressAtom, null);
  set(publicAssessmentErrorAtom, null);

  if (value.type === "address")
    request(endpoint, GET_ADRESSE_DATA, {
      id: value.id,
    })
      .then((data) => {
        const latestAssessment =
          data?.offentlige_vurderinger_paa_adresse?.reduce((a, b) =>
            a.vurderingsaar > b.vurderingsaar ? a : b
          );

        set(publicAddressAtom, latestAssessment?.vurderingsejendom_adresse);
        set(publicAssessmentAtom, data?.offentlige_vurderinger_paa_adresse);
        set(activeAssessmentYearAtom, latestAssessment?.vurderingsaar);
      })
      .catch(() => {});

  if (value.type === "bfe")
    request(endpoint, GET_BFE_DATA, {
      id: value.id,
    })
      .then((data) => {
        const latestAssessment = data?.offentlige_vurderinger_paa_bfe?.reduce(
          (a, b) => (a.vurderingsaar > b.vurderingsaar ? a : b)
        );

        set(publicAddressAtom, latestAssessment?.vurderingsejendom_adresse);
        set(publicAssessmentAtom, data?.offentlige_vurderinger_paa_bfe);
        set(activeAssessmentYearAtom, latestAssessment?.vurderingsaar);
      })
      .catch(() => {});
});
