let currentId = 0;

export function generateModuleId(prepend?: string) {
  // Create a semi unique ID. This is only used inside Umbraco, so it's not critical that they are unique.
  const id = (currentId += 1).toString();

  return `${prepend ? prepend + "-" : ""}${id.padStart(2, "0")}`;
}

export function reset() {
  currentId = 0;
}
