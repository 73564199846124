import { useAtom } from "jotai";

import { activeYearAtom } from "@/store";

import { PropertyIdAndYear } from "../../graphql/types";
import useQueryParams from "./useQueryParams";

const usePropertyIdAndValuationYear = (): PropertyIdAndYear => {
  const [activeYear] = useAtom(activeYearAtom);
  const queryParams = useQueryParams();
  const propertyId: number | undefined = queryParams.ejendomsid as number;
  const valuationYear =
    activeYear && propertyId && activeYear[propertyId]?.year;

  return { propertyId, valuationYear };
};

export default usePropertyIdAndValuationYear;
