/**
 * A static version of the spinner, that can be used when rendering serverside
 **/
const staticSpinnerHTML = `<div class="vp-placeholder">
  <div class="vp-spinner">
    <div class="vp-spinner--inner">
      <span class="vp-spinner--box vp-spinner--box__1"></span>
      <span class="vp-spinner--box vp-spinner--box__2"></span>
      <span class="vp-spinner--box vp-spinner--box__3"></span>
    </div>
  </div>
</div>
`;

export default staticSpinnerHTML;
