import { rem } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Button from "@/components/Button/Button";
import Card from "@/components/Card/Card";
import Grid from "@/components/Grid/Grid";
import Heading from "@/components/Heading/Heading";
import Paragraph from "@/components/Paragraph/Paragraph";

type Props = {
  title?: string;
  description?: string;
  name: string;
  outline: boolean;
  debug?: boolean;
  details: Array<{
    name: string;
    value: any;
  }>;
  handleReload?: () => void;
};

const StyledErrorCard = styled(Card)<{ outline?: boolean }>`
  outline: ${(p) => p.outline && "2px red dashed"};
  && {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

const StyledSummary = styled.summary`
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

const StyledStack = styled.pre`
  background: rgba(0, 0, 0, 0.6);
  color: rgb(232, 232, 232);
  line-height: 1.4;
  white-space: pre;
  font-family: Menlo, Consolas, monospace;
  font-size: ${rem(14)};
  padding: 15px;
  margin: 0;
  overflow-x: scroll;
  margin-bottom: 0.5rem;
`;

const StyledDivider = styled.hr`
  margin: 1rem 0;
`;

const serializeProps = (props) =>
  JSON.stringify(
    {
      ...props,
      children: undefined,
      i18n: undefined,
    },
    null,
    2
  )
    .replace(/\\u002F/g, "/")
    .replace(/\\u003C/g, "<")
    .replace(/\\u003E/g, ">");

/**
 * Renders a ErrorMessage to the DOM when a component fails to render.
 */
const FriendlyError = ({
  title,
  description,
  name,
  details,
  debug,
  outline,
  handleReload,
}: Props) => {
  const [t, , ready] = useTranslation("common");

  if (!ready) return null;

  return (
    <Grid width="sm">
      <StyledErrorCard outline={outline}>
        <Heading level={2}>{title || t("moduleErrorTitle")}</Heading>

        <Paragraph marginBottom={!!handleReload}>
          {description || t("moduleErrorMessage")}
        </Paragraph>

        {handleReload && (
          <Button onClick={handleReload} variation="primary">
            {t("moduleErrorButton")}
          </Button>
        )}

        {debug && details ? <StyledDivider /> : null}

        {debug &&
          details &&
          details.map(
            ({ name, value }) =>
              value && (
                <details key={name}>
                  <StyledSummary>{name}</StyledSummary>
                  <StyledStack>
                    <code>
                      {typeof value === "string"
                        ? value
                        : serializeProps(value)}
                    </code>
                  </StyledStack>
                </details>
              )
          )}
      </StyledErrorCard>
    </Grid>
  );
};

FriendlyError.defaultProps = {
  outline: true,
};

export default FriendlyError;
